export const mobile = {
  background: '#FEFEFE',
  surface: '#FEFEFE',
  primary: '#36577D',
  secondary: '#E2813A',
  appbar: '#36577D',
  grey: '#424242',
  excel: '#006600'
}

export const desktop = {
  background: '#eceef0',
  surface: '#FEFEFE',
  primary: '#36577D',
  secondary: '#E2813A',
  appbar: '#36577D',
  grey: '#424242',
  excel: '#006600'
}
