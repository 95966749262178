<template>
  <v-avatar
    color="white"
    size="x-small"
    class="bordered-avatar"
  >
    <v-img
      v-if="user?.image"
      :src="user.image.thumb_url"
      :alt="initials"
      cover
    />
    <span
      v-else
      class="text-primary text-caption font-weight-bold"
    >{{ initials }}</span>
  </v-avatar>
</template>

<script
  setup
  lang="ts"
>
const initials = computed(() => {
  return `${props.user?.first_name.charAt(0)}${props.user?.last_name.charAt(0)}`
})

const props = defineProps({
  user: {
    type: Object as PropType<BaseUser | User | UserTag>,
    required: true
  }
})

</script>
