<template>
  <v-app-bar
    density="compact"
    color="appbar"
    elevation="0"
  >
    <template #prepend>
      <v-app-bar-title class="me-4">
        <div class="d-flex">
          <v-btn
            v-if="!items.includes(route.fullPath)"
            size="small"
            icon="mdi-arrow-left"
            class="me-2"
            @click="router.back()"
          />
          <nuxt-link
            to="/"
            class="appbar-title d-flex align-center"
          >
            <v-img
              src="/logo.png"
              contain
              width="32"
            />
            <span
              v-if="!mobile || userIsAdmin"
              class="text-h6 mx-2"
            >
              {{ config.public.appName }}
            </span>
          </nuxt-link>
        </div>
      </v-app-bar-title>

      <!--SELECT LODGING-->
      <lodging-selector v-if="!userIsAdmin && mobile" />

      <navigation-selectors v-if="userIsManager && !mobile" />
    </template>

    <template #append>
      <!--SELECT LODGING-->
      <lodging-selector v-if="!userIsAdmin && !mobile" />

      <!--MENU UTENTE-->
      <user-menu />

      <!--NOTIFICHE-->
      <notification-icon-button @click="onToggleNotifications" />

      <lazy-notification-drawer
        v-if="!mobile"
        v-model="notificationStore.notificationDrawer"
      />

    </template>

  </v-app-bar>
  <v-app-bar
    v-if="mobile && (userIsAdmin || userIsManager)"
    color="primary"
    density="compact"
  >
    <navigation-selectors v-if="userIsManager" />
  </v-app-bar>
</template>

<script
  setup
  lang="ts"
>
import { useDisplay } from 'vuetify'
import { useNotificationsStore } from '~/store/notifications'

const config = useRuntimeConfig()
const notificationStore = useNotificationsStore()
const { mobile } = useDisplay()
const route = useRoute()
const router = useRouter()

const userIsAdmin = useIsAdmin()
const userIsManager = useIsManager()

const items = [
  '/tasks',
  '/users',
  '/statistics'
]

/**
 * Notifiche
 */
function onToggleNotifications() {
  if (mobile.value) {
    if (route.path.includes('notifications')) {
      router.back()
    } else {
      navigateTo('/notifications')
    }
  } else {
    notificationStore.setNotificationDrawer(true)
  }
}

</script>

<style
  scoped
  lang="scss"
>
.appbar-title {
  color: inherit;
  text-decoration: inherit;
  font-size: 1rem;
}
</style>
