<template>
  <v-app>
    <v-container class="d-flex fill-height flex-column justify-center align-center">
      <h1 class="text-h1">{{ t('err.ops') }}</h1>
      <h1 class="text-h3 mt-5">{{ t('err.occurred') }}</h1>
      <h2 class="text-h3 mt-10">
        {{ error?.statusCode }}
      </h2>
      <h3
        v-if="error?.message"
        class="text-h6"
      >{{ error.message }}</h3>
      <div class="mt-10">
        <v-btn
          v-if="!error?.fatal"
          :block="true"
          to="/"
        >
          {{ t('btn.back_home') }}
        </v-btn>
        <v-btn
          :block="true"
          variant="text"
          to="/logout"
          class="mt-2"
        >
          {{ t('btn.logout') }}
        </v-btn>
      </div>
    </v-container>
  </v-app>
</template>

<script
  setup
  lang="ts"
>

const error = useError()
const {t} = useI18n()

</script>
